.app__work {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__work-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    margin: 4rem 0 2rem;

    .app__work-filter-item {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: #fff;
        color: #000;
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0.5rem;

        &:hover {
            background-color: var(--secondary-color);
            color: #fff;
        }

        @media screen and (min-width: 2000px) {
            padding: 1rem 2rem;
            border-radius: 0.85rem;
        }
    }

    .item-active {
        background-color: var(--secondary-color);
        color: #fff;
    }
}

.app__work-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;

    .app__work-item {
        width: 270px;
        flex-direction: column;
        margin: 2rem;
        padding: 1rem;
        background-color: #fff;
        color: #000;

        border-radius: 0.5rem;
        background: #18192a;
        box-shadow: 8px 8px 9px #10111c,
                    -8px -8px 9px #202238;

        
        transition: all 0.3s ease;

        @media screen and (min-width: 2000px) {
            width: 400px;
            padding: 1.25rem;
            border-radius: 0.75rem;
        }

        @media screen and (max-width: 300px) {
            height: 100%;
            margin: 1rem;
        }
    }
}

.app__work-img {
    width: 100%;
    height: 230px;

    position: relative;

    img {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        object-fit: cover;
    }

    @media screen and (min-width: 2000px) {
        height: 350px;
    }
}

.app__work-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.5);

    border-radius: 0.5rem;
    opacity: 0;

    @media screen and (max-width: 950px) {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.0);
    }
    

    div {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;

        margin: 1rem;
        font-family: var(--font-base);
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;

        svg {
            width: 50%;
            height: 50%;
            color: var(--white-color);
        }
    }
}

.app__work-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;

    h4 {
        margin-top: 1rem;
        line-height: 1.5;
        color: var(--light-blue);
        letter-spacing: 0.05rem;
        font-weight: 400;

        @media screen and (min-width: 2000px) {
            font-size: 1.5rem;
        }
    }

    p {
        color: var(--light-color);
        font-size: 0.8rem;
        letter-spacing: 0.04rem;
        
        @media screen and (min-width: 2000px) {
            font-size: 1rem;
        }
    }

    .app__work-tag {
        position: absolute;
        padding: 0.5rem 1rem;
        border-radius: 10px;
        background-color: #18192a;
        top: -25px;
    }
}

.app__work-head {
    background: -webkit-linear-gradient(30deg, #12c2e9, #c471ed, #f64f59, rgb(199, 219, 14));
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.7rem;

    @media screen and (min-width: 1200px) {
        font-size: 3.5rem;
    }

    @media screen and (min-width: 1200px) {
        font-size: 4.5rem;
    }
}