.app__footer {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__footer-cards {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;

    margin: 4rem 2rem 2rem;

    .app__footer-card {
        min-width: 290px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        margin: 1rem 0;
        padding: 1rem;
        
        cursor: pointer;
        

        border-radius: 0.5rem;
        background: #383d5b;
        box-shadow: 8px 8px 9px #25283c,
                    -8px -8px 9px #4b527a;

        transition: all 0.3s ease-in-out;

        img {
            width: 40px;
            height: 40px;
            margin: 0 0.7rem;
        }

        p {
            font-weight: 600;
        }

        a {
            text-decoration: none;
            font-weight: 500;
        }

        &:hover {
            box-shadow: inset 8px 8px 9px #25283c,
                        inset -8px -8px 9px #4b527a;
        }

        @media screen and (max-width: 450px) {
            width: 90%;
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    @media screen and (max-width: 450px) {
        justify-content: center;
    }

    @media screen and (max-width: 860px) {
        justify-content: center;
    }
}

.app__footer-form {
    width: 60%;
    flex-direction: column;
    margin: 1rem 2rem;
    padding: 0.5rem 1rem;

    border-radius: 0.5rem;
    background: #383d5b;
    box-shadow: 8px 8px 9px #25283c,
                -8px -8px 9px #4b527a;

    div {
        width: 100%;
        margin: 0.75rem 0;
        border-radius: 10px;
        cursor: pointer;
        background-color: var(--secondarybg-color);

        input, textarea {
            width: 100%;
            padding: 0.95rem;
            border: 1px solid var(--primarybg-color);
            border-radius: 0.5rem;
            background-color: var(--secondarybg-color);

            font-family: var(--font-base);
            color: var(--light-color);
            font-weight: 600;
            font-size: 1rem;
            outline: none;
            
        }

        textarea {
            height: 170px;
        }

    }

    button {
        padding: 1rem 2rem;
        border-radius: 0.5rem;
        border: none;
        background-color: var(--primarybg-color);
        font-weight: 500;
        color: var(--light-color);
        outline: none;
        margin: 2rem 0 0 0;
        font-family: var(--font-base);
        cursor: pointer;
        margin-bottom: 1.5rem;

        transition: all 0.3s ease-in-out;

        background: var(--secondarybg-color);
        box-shadow: 8px 8px 9px #25283c,
                -8px -8px 9px #4b527a;

        &:hover {
            background: var(--secondarybg-color);
            box-shadow: inset 8px 8px 9px #25283c,
                        inset -8px -8px 9px #4b527a;
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        margin: 1rem 0;
    }

    @media screen and (max-width: 450px) {
        width: 90%;
    }
}

.app__footer-sub {
    background: -webkit-linear-gradient(30deg, #12c2e9, #c471ed, #f64f59, rgb(199, 219, 14));
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 1.5rem;

    @media screen and (min-width: 1200px) {
        font-size: 3rem;
    }
}

.app__footer--inputs {
    position: relative;
}

.app__footer--input {
    border: solid 1.5px #9e9e9e;
    border-radius: 1rem;
    background: none;
    padding: 1rem;
    font-size: 1rem;
    color: #f5f5f5;
    transition: border 150ms cubic-bezier(0.4,0,0.2,1);
}

.app__footer--label {
    position: absolute;
    top: 0;
    left: 15px;
    color: var(--light-color);
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4,0,0.2,1);
    font-size: 1rem;
    letter-spacing: 0.05rem;
    font-weight: 600;
}

.app__footer--input:focus, 
.app__footer--input:valid {
    outline: none;
}

.app__footer--input:focus ~ label, 
.app__footer--input:valid ~ label {
    transform: translateY(-50%) scale(0.8);
    padding: 0 .2em;
    color: var(--light-color);
    background-color: var(--secondarybg-color);
}

::placeholder {
    color: var(--light-color);
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px var(--secondarybg-color) inset !important;
}

input:-webkit-autofill{
    -webkit-text-fill-color: var(--light-color) !important;
}