.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;

    
}

.app__profile-item {
    width: 230px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;
    padding: 1rem;

    border-radius: 0.5rem;
    background: #383d5b;
    box-shadow: 8px 8px 9px #25283c,
            -8px -8px 9px #4b527a;

    img {
        width: 100%;
        height: 170px;
        border-radius: 0.5rem;
        object-fit: cover;
    }

    @media screen and (min-width: 2000px) {
        width: 370px;
        margin: 2rem 4rem;

        img {
            height: 320px;
        }
    }
}

.app__about-item-h2 {
    font-size: 1.1rem;
    color: var(--light-blue);
    letter-spacing: 0.03rem;
    font-weight: 500;
}

.app__about-item-p {
    font-size: 0.9rem;
    color: var(--light-color);
    line-height: 1.0rem;
    letter-spacing: 0.05rem;
    font-weight: 200;
}