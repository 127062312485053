#home {
    position: relative;
    
    background-size: cover;
    background-repeat: repeat;
    background-position: center;

    .app__wrapper {
        padding: 0;

        .copyright {
            display: none;
        }
    }
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;

    padding: 6rem 2rem 0;

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }

    @media screen and (min-width: 450px) {
        padding: 6rem 1rem 2rem;
    }
}

.app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    height: 100%;

    @media screen and (max-width: 2000px) {
        width: 100%;
        margin-right: 0rem;
    }
}

.app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    

    border-radius: 0.5rem;
    background: #18192a;
    box-shadow:  8px 8px 9px #10111c,
                -8px -8px 9px #202238;

    .badge-cmp,
    .tag-cmp {
        padding: 1rem 2rem;
        flex-direction: row;
        width: auto;
    }

    .tag-cmp {
        flex-direction: column;
        margin-top: 3rem;

        p {
            width: 100%;
            text-transform: uppercase;
            text-align: right;
        }
    }

    @media screen and (min-width: 2000px) {
        margin-top: 2rem;
    }


    @media screen and (max-width: 1200px) {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;

    div {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: #383d5b;
        box-shadow: 0px 3px 3px #10111c;
        transition: all 0.2s ease-in-out;

        img {
            width: 60%;
            height: 60%;
        }
    }

    div:nth-child(1) {
        width: 70px;
        height: 70px;

        @media screen and (min-width: 2000px) {
            width: 90px;
            height: 90px;
        }
    }


    div:nth-child(2) {
        margin: 1.75rem;
        width: 70px;
        height: 70px;

        @media screen and (min-width: 2000px) {
            width: 90px;
            height: 90px;
        }
    }

    div:nth-child(3) {
        width: 70px;
        height: 70px;

        @media screen and (min-width: 2000px) {
            width: 90px;
            height: 90px;
        }
    }

    div:nth-child(4) {
        width: 70px;
        height: 70px;
        margin: 1.75rem;

        @media screen and (min-width: 2000px) {
            width: 90px;
            height: 90px;
        }
    }

    

    @media screen and (max-width: 1200px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div {
            margin: 1rem;
        }
    }
    
}

.app__header-img {
    flex: 1;
    height: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;

    img {
        width: 90%;
        object-fit: contain;
        z-index: 1;
        border-radius: 20%;
    }

    .overlay_circle {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10%;
        z-index: 0;
        width: 100%;
        height: 90%;
    }

    @media screen and (max-width: 1200px) {
        margin: 2rem 0;
    }
}

.app__header-name { 
    background: -webkit-linear-gradient(30deg, #12c2e9, #c471ed, #f64f59, rgb(199, 219, 14));
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 0.036rem;
    margin-bottom: 0;
    width: 9.8rem;

    @media screen and (min-width: 2000px) {
        font-size: 4rem;
        width: 13rem;
    }

    @media screen and (max-width: 450px) {
        font-size: 2rem;
    }
}

.app__header-text {
    font-size: 0.9rem;
    text-align: left;
    color: var(--light-color);
    line-height: 1.5;
    letter-spacing: 0.04rem;
    font-weight: 400;

    @media screen and (min-width: 2000px) {
        font-size: 1.3rem;
    }

    @media screen and (max-width: 450px) {
        font-size: 0.8rem;
    }
}